import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import style from './Modal.module.scss';

ReactModal.setAppElement('body');

const Modal = props => {
    const { isOpen, children, modalClass } = props;

    return (
        <ReactModal
            id='modal'
            isOpen={isOpen}
            overlayClassName={style.overlay}
            className={[style.modal, modalClass].join(' ')}
            contentLabel='Modal'
            closeTimeoutMS={800}
        >
            {children}
        </ReactModal>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    modalClass: PropTypes.string,
    children: PropTypes.node,
};

Modal.defaultProps = {};

export default Modal;
