export const loadAdditionalScript = (script) => {
    const additionalScript = document.querySelector('#additional-tracking');
    if (additionalScript) {
        additionalScript.remove();
    }
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('id', 'additional-tracking');
    scriptTag.innerHTML = `${script}`;
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    return false;
};
