import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
// import { useDispatch } from 'react-redux';

import { SmartLink } from '../SmartLink';
/* Hide Code: SubHide.  import Subscribe from '../Subscribe'; */

import style from './Footer.module.scss';

const Footer = props => {
    const { /* Hide Code: SubHide.  title, */ logoImage, logoMobileImage, logoLink, navigation, /* Hide Code: SubHide.  subscription, */ copyrightText, socialMedia } = props;

    // const colmark = new Array(navigation.length).fill(false);

    const isMobile = useMediaQuery('only screen and (max-width: 599px)');

    return (
        <footer className={style.el} id='footer'>
            <div className={style.top}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.sitemap}>
                        {navigation.map((col, i) => (
                            <div className={style.sitemapCol} key={i}>
                                {col.map((blk, k) => (
                                    <div
                                        key={k}
                                        className={[style.sitemapBlock, k === 0 ? style.first : style.last].join(' ')}
                                        style={{
                                            '--max-h': `${blk.items.length * 25}px`,
                                        }}
                                    >
                                        <h5>
                                            <SmartLink href={blk.link.url}>{blk.text}</SmartLink>
                                        </h5>
                                        {blk.items.map((link, j) => (
                                            <SmartLink
                                                key={`sitemaplink${j}`}
                                                className={style.sitemapLink}
                                                href={link.link.url}
                                            >
                                                {link.text}
                                            </SmartLink>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {/* navigation.map((col, i) => {
                            const colLong = col.items.length > 3;
                            let nextCol = null;
                            if (i < navigation.length - 1) {
                                nextCol = navigation[i + 1];
                            }
                            if (
                                !colLong &&
                                nextCol?.items.length <= 3 &&
                                !colmark[i]
                            ) {
                                colmark[i + 1] = true;
                            }
                            return (
                                <Fragment key={`sitemapcol${i}`}>
                                    {!colLong &&
                                    nextCol?.items.length <= 3 &&
                                    !colmark[i] ? (
                                        <div className={style.sitemapCol}>
                                            <div
                                                className={[
                                                    style.sitemapBlock,
                                                    style.first,
                                                ].join(' ')}
                                                style={{
                                                    '--max-h': `${col.items
                                                        .length * 25}px`,
                                                }}
                                            >
                                                <h5>
                                                    <SmartLink
                                                        href={col.link.url}
                                                    >
                                                        {col.text}
                                                    </SmartLink>
                                                </h5>
                                                {col.items.map((link, j) => (
                                                    <SmartLink
                                                        key={`sitemaplink${j}`}
                                                        className={
                                                            style.sitemapLink
                                                        }
                                                        href={link.link.url}
                                                    >
                                                        {link.text}
                                                    </SmartLink>
                                                ))}
                                            </div>
                                            <div
                                                className={[
                                                    style.sitemapBlock,
                                                    style.last,
                                                ].join(' ')}
                                            >
                                                <h5>
                                                    <SmartLink
                                                        href={nextCol.link.url}
                                                    >
                                                        {nextCol.text}
                                                    </SmartLink>
                                                </h5>
                                                {nextCol.items.map(
                                                    (link, j) => (
                                                        <SmartLink
                                                            key={`sitemaplink${j}`}
                                                            className={
                                                                style.sitemapLink
                                                            }
                                                            href={link.link.url}
                                                        >
                                                            {link.text}
                                                        </SmartLink>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : colmark[i] ? (
                                        <></>
                                    ) : (
                                        <div
                                            key={`sitemapcol${i}`}
                                            className={style.sitemapCol}
                                        >
                                            <div className={style.sitemapBlock}>
                                                <h5>
                                                    <SmartLink
                                                        href={col.link.url}
                                                    >
                                                        {col.text}
                                                    </SmartLink>
                                                </h5>
                                                {col.items.map((link, j) => (
                                                    <SmartLink
                                                        key={`sitemaplink${j}`}
                                                        className={
                                                            style.sitemapLink
                                                        }
                                                        href={link.link.url}
                                                    >
                                                        {link.text}
                                                    </SmartLink>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }) */}
                    </div>
                    <div className={style.logo}>
                        <SmartLink href={logoLink}>
                            {isMobile && logoMobileImage?.url ? (
                                <img src={logoMobileImage?.url} alt={logoMobileImage?.alt} />
                            ) : (
                                <img src={logoImage.url} alt={logoImage?.alt} />
                            )}
                        </SmartLink>
                    </div>
                </div>
            </div>
            <div className={style.bottom}>
                <div className={[style.container, 'container'].join(' ')}>
                    {/* Hide Code: SubHide.  Temporarily hidden until we wish to address and potentially restyle */}
                    {/* <div className={style.flexrow}>
                        <div className={style.largeTitle}>{title}</div>
                        <Subscribe {...subscription} />
                    </div> */}
                    <div className={[style.flexrow, style.last].join(' ')}>
                        <div className={style.social}>
                            {socialMedia.map(item => (
                                <SmartLink key={`social${item.icon}`} href={item.link.url} aria-label={item.icon}>
                                    <span className={`icon-${item.icon}`} aria-hidden='true' />
                                </SmartLink>
                            ))}
                        </div>
                        <div className={style.copyright}>{copyrightText}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    title: PropTypes.string,
    navigation: PropTypes.array,
    logoImage: PropTypes.object,
    logoMobileImage: PropTypes.object,
    logoLink: PropTypes.string,
    subscription: PropTypes.object,
    copyrightText: PropTypes.string,
    socialMedia: PropTypes.array,
};

Footer.defaultProps = {
    logoImage: {
        url: '/images/logo-white.png',
        alt: '',
    },
    title: 'Connect with us',
    subscription: {
        title: 'Connect with us',
        fieldname: 'email',
        placeholder: 'EMAIL ADDRESS',
        errorText: 'please enter your email address',
        buttonLabel: 'SUBMIT',
    },
    copyrightText: '© 2021 Permira I.P Limited',
    navigation: [
        [
            {
                text: 'People',
                link: {
                    url: '/people',
                    target: '',
                },
                items: [
                    {
                        text: 'Meet Our People',
                        link: {
                            url: '/meet-our-people',
                            target: '',
                        },
                    },
                    {
                        text: 'Culture and Values',
                        link: {
                            url: '/culture-and-values',
                            target: '',
                        },
                    },
                    {
                        text: 'Life at Permira',
                        link: {
                            url: '/life-at-permira',
                            target: '',
                        },
                    },
                ],
            },
            {
                text: 'Responsibility',
                link: {
                    url: '/responsibility',
                    target: '',
                },
                items: [
                    {
                        text: 'Investing Responsibly',
                        link: {
                            url: '/investing-responsibly',
                            target: '',
                        },
                    },
                    {
                        text: 'Permira Foundation',
                        link: {
                            url: '/permira-foundation',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Investing',
                link: {
                    url: '/investing',
                    target: '',
                },
                items: [
                    {
                        text: 'Private Equity Buyout',
                        link: {
                            url: '/private-equity-buyout',
                            target: '',
                        },
                    },
                    {
                        text: 'Private Equity Growth',
                        link: {
                            url: '/private-equity-growth',
                            target: '',
                        },
                    },
                    {
                        text: 'Credit',
                        link: {
                            url: '/credit',
                            target: '',
                        },
                    },
                ],
            },
            {
                text: 'About',
                link: {
                    url: '/about',
                    target: '',
                },
                items: [
                    {
                        text: 'Permira Today',
                        link: {
                            url: '/permira-today',
                            target: '',
                        },
                    },
                    {
                        text: 'Insights',
                        link: {
                            url: '/insights',
                            target: '',
                        },
                    },
                    {
                        text: 'Media',
                        link: {
                            url: '/media',
                            target: '',
                        },
                    },
                    {
                        text: 'In the News',
                        link: {
                            url: '/in-the-news',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Portfolio',
                link: {
                    url: '/portfolio',
                    target: '',
                },
                items: [
                    {
                        text: 'Our Portfolio',
                        link: {
                            url: '/our-portfolio',
                            target: '',
                        },
                    },
                    {
                        text: 'Technology',
                        link: {
                            url: '/technology',
                            target: '',
                        },
                    },
                    {
                        text: 'Consumer',
                        link: {
                            url: '/consumer',
                            target: '',
                        },
                    },
                    {
                        text: 'Services',
                        link: {
                            url: '/services',
                            target: '',
                        },
                    },
                    {
                        text: 'Healthcare',
                        link: {
                            url: '/healthcare',
                            target: '',
                        },
                    },
                ],
            },
        ],
        [
            {
                text: 'Others',
                link: {
                    url: '/others',
                    target: '',
                },
                items: [
                    {
                        text: 'Goverance',
                        link: {
                            url: '/goverance',
                            target: '',
                        },
                    },
                    {
                        text: 'Legal',
                        link: {
                            url: '/legal',
                            target: '',
                        },
                    },
                    {
                        text: 'Privacy Policy',
                        link: {
                            url: '/privacy-policy',
                            target: '',
                        },
                    },
                    {
                        text: 'Cookie Notice',
                        link: {
                            url: '/cookie-notice',
                            target: '',
                        },
                    },
                    {
                        text: 'MSA',
                        link: {
                            url: '/msa',
                            target: '',
                        },
                    },
                    {
                        text: 'PGL',
                        link: {
                            url: '/pgl',
                            target: '',
                        },
                    },
                ],
            },
        ],
    ],
    socialMedia: [
        {
            icon: 'wechat',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'vimeo',
            link: {
                target: '_blank',
                url: '#',
            },
        },
        {
            icon: 'youtube',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'twitter',
            link: {
                target: '',
                url: '#',
            },
        },
        {
            icon: 'linkedin',
            link: {
                target: '',
                url: '#',
            },
        },
    ],
};

export default Footer;
