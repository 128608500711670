import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

//* Styles
import styles from './BackToTop.module.scss';

const BackToTop = props => {
    const [show, setShow] = useState(false);
    const [footerVisible, setFooterVisible] = useState(false);

    const isAppearing = (el, offset = 0) => {
        const rect = el.getBoundingClientRect();
        return !(
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.top > (window.innerHeight || document.documentElement.clientHeight) - offset
        );
    };

    useScrollPosition(
        ({ currPos }) => {
            setShow(currPos.y > (window.innerHeight || document.documentElement.clientHeight));
            setFooterVisible(isAppearing(document.getElementById('footer'), 104));
        },
        [],
        false,
        true,
        200
    );

    const handleClick = () => {
        scroll.scrollToTop({
            duration: 600,
        });
    };

    return (
        <div className={[styles.el, footerVisible ? styles.sticky : ''].join(' ')}>
            <button type='button' className={[styles.inner, show ? styles.show : null].join(' ')} onClick={handleClick}>
                <span className='icon-arrow-up' aria-hidden='true' />
                <span className={[styles.secretArrow, 'icon-arrow-up'].join(' ')} aria-hidden='true' />
                <span className='sr-only'>back to top</span>
            </button>
        </div>
    );
};

export default BackToTop;
