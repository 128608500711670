import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// import router from 'next/router';
import { useRouter } from 'next/router';
// import { useWindowHeight } from '@react-hook/window-size/throttled';
import { useMediaQuery } from '@react-hook/media-query';
// import {
//     disableBodyScroll,
//     enableBodyScroll,
//     clearAllBodyScrollLocks,
// } from 'body-scroll-lock';
// import _ from 'lodash';

import { SmartLink } from '../SmartLink';
// import { useDispatch, useSelector } from 'react-redux';

import style from './Header.module.scss';

const SecondNav = props => {
    const { text, link, icon, children } = props;
    const linkRef = useRef(null);
    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');
    const [popover, setPopover] = useState(false);
    const popOverClick = () => {
        setPopover(!popover);
    };
    const closePopover = e => {
        if (e.target.parentNode !== linkRef.current && isDesktop) {
            setPopover(false);
        }
    };
    const isFirstLoad = useRef(true);

    useEffect(() => {
        if (isFirstLoad.current) {
            window.addEventListener('scroll', closePopover, true);
            document.body.addEventListener('click', closePopover, true);
            isFirstLoad.current = false;
        }
        return () => {
            window.removeEventListener('scroll', closePopover);
            document.body.removeEventListener('click', closePopover);
        };
    }, []);

    return (
        <div key={text} className={style.secondNavItem} ref={linkRef}>
            {children.length > 0 ? (
                <>
                    <SmartLink href={link.url} target={link.target} onClick={popOverClick} className={style.hasChild}>
                        {text}
                        <span className={[style.arrowDown, popover ? style.active : ''].join(' ')} />
                    </SmartLink>
                    <div className={[style.secondNavPop, popover ? style.active : ''].join(' ')}>
                        {children.map(item => (
                            <SmartLink href={item.link.url} target={item.link.target} key={item.text}>
                                {item.text}
                            </SmartLink>
                        ))}
                    </div>
                </>
            ) : (
                <SmartLink href={link.url} target={link.target}>
                    {text}
                    {icon ? (
                        <span className={icon} aria-hidden='true' />
                    ) : text.toLowerCase().indexOf('login') > -1 ? (
                        <span className='icon-login' aria-hidden='true' />
                    ) : null}
                </SmartLink>
            )}
        </div>
    );
};

SecondNav.propTypes = {
    text: PropTypes.string,
    colourOption: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.object,
    children: PropTypes.array,
};

const MegaMenu = ({ id, item, setMenuOpen, mobileExpand, setMobileExpand, setMegaExpand }) => {
    const [adjoiningContentVisible, setAdjoiningContentVisible] = useState(null);

    return (
        <div className={[style.megaWrap, mobileExpand === id ? style.active : ''].join(' ')}>
            <div className={style.mobileHeader}>
                <button type='button' className={style.backBtn} onClick={() => setMobileExpand(-1)}>
                    <span className='icon-arrow-right' aria-hidden='true' />
                    <span className='sr-only'>back</span>
                </button>
                <h5>{item.text}</h5>
                <button
                    className={style.closeBtn}
                    onClick={() => {
                        setMenuOpen(false);
                        setMobileExpand(-1);
                    }}
                >
                    <span className='icon-close' aria-hidden='true' />
                    <span className='sr-only'>close</span>
                </button>
            </div>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.subnavs}>
                    {item.children.map((sub, index) => (
                        <SmartLink
                            href={sub.link.url}
                            key={sub.text}
                            className={
                                (sub.leftPadding ? style.leftPadding : '') +
                                ' ' +
                                (sub.colourOption === 'Orange'
                                    ? style.orange
                                    : sub.colourOption === 'Default'
                                    ? style.default
                                    : '')
                            }
                            onClick={() => {
                                setMenuOpen(false);
                                setMobileExpand(-1);
                                setMegaExpand(-1);
                            }}
                            onMouseEnter={() => setAdjoiningContentVisible(index)}
                            onMouseLeave={() => setAdjoiningContentVisible(null)}
                        >
                            {sub.text}
                            <span className='icon-arrow-right' aria-hidden='true' />
                        </SmartLink>
                    ))}
                </div>
                <div className={style.info}>
                    <div className={style.adjoiningContent + ' ' + style.default}>
                        <div className={style.image}>
                            <img src={item.image?.url} alt={item.image?.alt} />
                        </div>
                        <div className={style.content}>
                            <div className={style.tag}>{item.text}</div>
                            <div
                                className={style.description}
                                dangerouslySetInnerHTML={{
                                    __html: item.description,
                                }}
                            />
                            {item.cta.link.url !== '' && (
                                <>
                                    <div className={style.cta}>
                                        <SmartLink
                                            href={item.cta.link.url}
                                            onClick={() => {
                                                setMenuOpen(false);
                                                setMobileExpand(-1);
                                                setMegaExpand(-1);
                                            }}
                                        >
                                            {item.cta.text}
                                            <span className='icon-arrow-right' aria-hidden='true' />
                                        </SmartLink>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {item.children.map((child, index) => (
                        (child.description.length || child.image?.url.length)
                        ? (
                            <div key={index} className={style.adjoiningContent + ' ' + (adjoiningContentVisible === index ? style.visible : '')}>
                                <div className={style.image}>
                                    {(child.image?.url) ? (
                                        <img src={child.image?.url} alt={child.image?.alt} />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className={style.content}>
                                    <div className={style.tag}>{child.text}</div>
                                    <div
                                        className={style.description}
                                        dangerouslySetInnerHTML={{
                                            __html: child.description,
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

MegaMenu.propTypes = {
    item: PropTypes.object,
    setMenuOpen: PropTypes.func,
    setMobileExpand: PropTypes.func,
    setMegaExpand: PropTypes.func,
    mobileExpand: PropTypes.number,
    id: PropTypes.number,
};

const Header = props => {
    const {
        searchLabel,
        logoWhiteImage,
        logoBlueImage,
        logoLink,
        navigation,
        secondNavigation,
        search,
        socialMedia,
        noSplash,
    } = props;

    const mobileMenuRef = useRef(null);
    const searchRef = useRef(null);

    const [scrolled, setScrolled] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [mobileExpand, setMobileExpand] = useState(-1);
    const [megaExpand, setMegaExpand] = useState(-1);

    // const dispatch = useDispatch();
    // const state = useSelector(s => s);

    const router = useRouter();
    const triggerMenu = e => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    };

    const handleSearchClick = e => {
        e.preventDefault();
        setSearchOpen(!searchOpen);
    };

    const handleExpand = e => {
        e.preventDefault();
        setMobileExpand(parseInt(e.currentTarget.getAttribute('data-id')));
    };

    // const wHeight = useWindowHeight();

    // useEffect(() => {
    //     const vh = wHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // }, [wHeight]);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');

    useEffect(() => {
        if (isDesktop) {
            closeMobileMenu();
        }
    }, [isDesktop]);

    useEffect(() => {
        if (menuOpen) {
            setSearchOpen(false);
            // disableBodyScroll(mobileMenuRef.current);
        } else {
            // enableBodyScroll(mobileMenuRef.current);
        }
    }, [menuOpen]);

    const closeMobileMenu = () => {
        setMenuOpen(false);
        setMobileExpand(-1);
    };

    const openMega = e => {
        setMegaExpand(parseInt(e.currentTarget.getAttribute('data-id')));
    };

    const closeMega = e => {
        setMegaExpand(-1);
        // setMobileExpand(-1);
    };

    const closeSearch = e => {
        setSearchOpen(false);
        e.preventDefault();
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
             if (e.key === 'Escape') closeSearch(e);
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (searchOpen) {
            searchRef.current.focus();
        }
    }, [searchOpen]);

    useEffect(() => {
        let prevScrollpos = window.pageYOffset;
        setScrolled(window.scrollY > 5);
        const handleWindowScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (window.scrollY > 5) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
            if (prevScrollpos > currentScrollPos || currentScrollPos < 200) {
                setMinimized(false);
            } else if (currentScrollPos > 200) {
                setMinimized(true);
            }
            prevScrollpos = currentScrollPos;
        };
        window.addEventListener('scroll', handleWindowScroll);
        // window.addEventListener('resize', closeMobileMenu);
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
            // window.removeEventListener('resize', closeMobileMenu);
            // clearAllBodyScrollLocks();
        };
    }, []);

    return (
        <header
            className={[
                style.el,
                scrolled || menuOpen || megaExpand > -1 ? style.scrolled : '',
                minimized ? style.minimized : '',
                noSplash ? style.noSplash : '',
                searchOpen ? style.searchOpen : '',
            ].join(' ')}
        >
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.left}>
                    <a className={style.logo + ' ' + (menuOpen ? style.active : '')} href={logoLink}>
                        <img className={style.logoWhite} src={logoWhiteImage.url} alt={logoWhiteImage.alt} />
                        <img className={style.logoBlue} src={logoBlueImage.url} alt={logoBlueImage.alt} />
                    </a>
                </div>
                <div className={style.mobileRight}>
                    <a onClick={handleSearchClick} href='#' className={style.searchBtnMobile} aria-label='search'>
                        <span className='icon-search' aria-hidden='true' />
                    </a>
                    <button
                        type='button'
                        onClick={triggerMenu}
                        className={[style.mobileMenuBtn, menuOpen ? style.active : ''].join(' ')}
                    >
                        <span />
                        <span />
                        <span />
                        <span className='sr-only'>menu</span>
                    </button>
                </div>
                <div
                    className={[style.right, menuOpen ? style.open : '', mobileExpand > -1 ? style.expanded : ''].join(
                        ' '
                    )}
                    ref={mobileMenuRef}
                >
                    <div className={style.mobileMenu}>
                        <div className={style.secondNav}>
                            {secondNavigation.map(item => (
                                <SecondNav {...item} key={item.text} />
                            ))}
                            <div className={[style.secondNavItem, style.search].join(' ')}>
                                <a onClick={handleSearchClick} href='#' aria-label='search'>
                                    <span className='icon-search' aria-hidden='true' />
                                </a>
                            </div>
                            <div className={style.social}>
                                {socialMedia.map(item => (
                                    <SmartLink key={`social${item.icon}`} href={item.link.url} aria-label={item.icon}>
                                        <span className={`icon-${item.icon}`} aria-hidden='true' />
                                    </SmartLink>
                                ))}
                            </div>
                        </div>
                        <div className={style.mainNav}>
                            {navigation.map((item, i) => {
                                const isActive =
                                    router.asPath.split('/')[1].toLowerCase() ===
                                    item.link.url.split('/')[1]?.toLowerCase();
                                return (
                                    <div
                                        key={`${item.text}-${i}`}
                                        className={[
                                            style.mainNavItem,
                                            isActive ? style.active : '',
                                            megaExpand === i ? style.isOpen : '',
                                        ].join(' ')}
                                        onMouseOver={openMega}
                                        onMouseOut={closeMega}
                                        data-id={i}
                                    >
                                        <SmartLink
                                            href={item.link.url}
                                            onClick={() => {
                                                setMenuOpen(false);
                                                setMobileExpand(-1);
                                            }}
                                        >
                                            {item.text}
                                            {item.children?.length > 0 && <span className='icon-arrow-down' />}
                                        </SmartLink>
                                        {item.children?.length > 0 && (
                                            <button className={style.expandBtn} onClick={handleExpand} data-id={i}>
                                                <span className='icon-arrow-right' aria-hidden='true' />
                                                <span className='sr-only'>expand</span>
                                            </button>
                                        )}
                                        {item.children?.length > 0 && (
                                            <MegaMenu
                                                id={i}
                                                item={item}
                                                mobileExpand={mobileExpand}
                                                setMenuOpen={setMenuOpen}
                                                setMobileExpand={setMobileExpand}
                                                setMegaExpand={setMegaExpand}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={[style.searchBox, searchOpen ? style.active : ''].join(' ')}>
                <div className={style.beforeOverlay}></div>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.wrapper}>
                        <form action={search.link.url}>
                            <label htmlFor='search-keyword' className='sr-only'>
                                Search
                            </label>
                            <input
                                id='search-keyword'
                                className={style.searchInput}
                                type='text'
                                placeholder={searchLabel}
                                name='keyword'
                                required
                                ref={searchRef}
                            />
                            <button className={style.searchSubmit} type='submit'>
                                <span className='icon-arrow-right' aria-hidden='true' />
                                <span className='sr-only'>submit</span>
                            </button>
                            <button className={style.searchCancel} onClick={closeSearch} aria-label="Close Search" title="Close Search"><span className='icon-close' aria-hidden='true' /></button>
                        </form>
                    </div>
                </div>
                <div className={style.afterOverlay} onClick={closeSearch}></div>
            </div>
        </header>
    );
};

Header.propTypes = {
    searchLabel: PropTypes.string,
    logoWhiteImage: PropTypes.object,
    logoBlueImage: PropTypes.object,
    logoLink: PropTypes.string,
    navigation: PropTypes.array,
    secondNavigation: PropTypes.array,
    search: PropTypes.object,
    socialMedia: PropTypes.array,
    noSplash: PropTypes.bool,
};

Header.defaultProps = {
    navigation: [
        {
            text: 'People',
            link: {
                target: '',
                url: '/people',
            },
            image: {
                url: 'https://permira-cm.azurewebsites.net/media/2dvjvxu5/rectangle-20364.png',
                alt: '',
            },
            description:
                'A global team bound by a distinct culture. Our collaborative and entrepreneurial culture unites a diverse team of 360+ individuals across 15 offices around the world.',
            cta: {
                text: 'MEET OUR PEOPLE',
                link: {
                    target: '',
                    url: '#',
                },
            },
            children: [
                {
                    text: 'Meet Our People',
                    link: {
                        target: '',
                        url: '/people/meet-our-people',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
                {
                    text: 'Culture and Values',
                    link: {
                        target: '',
                        url: '/people/culture-and-values',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
                {
                    text: 'Life at Permira',
                    link: {
                        target: '',
                        url: '/people/life-at-permira',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
            ],
        },
        {
            text: 'Investing',
            link: {
                target: '',
                url: '/investing',
            },
            image: {
                url: 'https://permira-cm.azurewebsites.net/media/2dvjvxu5/rectangle-20364.png',
                alt: '',
            },
            description:
                'Investing in global, market-leading businesses within the technology, consumer, healthcare and services sectors to drive growth.',
            cta: {
                text: 'READ MORE',
                link: {
                    target: '',
                    url: '#',
                },
            },
            children: [
                {
                    text: 'Private Equity Buyout',
                    link: {
                        target: '',
                        url: '/investing/private-equity-buyout',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
                {
                    text: 'Private Equity Growth',
                    link: {
                        target: '',
                        url: '/investing/private-equity-growth',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
                {
                    text: 'Credit',
                    link: {
                        target: '',
                        url: '/investing/credit',
                    },
                    image: {
                        url: '',
                        alt: '',
                    },
                    mobileImage: {
                        url: '',
                        alt: '',
                    },
                    description: '',
                    cta: {
                        text: '',
                        link: {
                            target: '',
                            url: '',
                        },
                    },
                    children: [],
                },
            ],
        },
        {
            text: 'Partnership',
            link: {
                target: '',
                url: '/partnership',
            },
        },
        {
            text: 'Responsibility',
            link: {
                target: '',
                url: '/responsibility',
            },
        },
        {
            text: 'About',
            link: {
                target: '',
                url: '/about',
            },
        },
    ],
    secondNavigation: [
        {
            text: 'CONTACT US',
            link: {
                target: '',
                url: '/dummy/offices',
            },
            isAuth: false,
        },
        {
            text: 'PERMIRA CHINA',
            link: {
                target: '_blank',
                url: 'https://www.permira.cn/',
            },
            isAuth: false,
        },
        {
            text: 'PERMIRA JAPAN',
            link: {
                target: '_blank',
                url: 'https://www.permira.jp/',
            },
            isAuth: false,
        },
        {
            text: 'INVESTORS LOGIN',
            link: {
                target: '',
                url: '#login',
            },
            isAuth: true,
            icon: 'icon-login',
        },
    ],
    logoWhiteImage: {
        url: '/images/logo-white.png',
        alt: '',
    },
    logoBlueImage: {
        url: '/images/logo-blue.png',
        alt: '',
    },
    logoLink: '/',
    search: {
        link: {
            url: '/search',
        },
    },
    socialMedia: [],
};

export default Header;
