import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { isExternalLink } from '../../utils/links';
import { checkMediaUrl } from '../../utils/common';
// import { useDispatch } from 'react-redux';
// import {
//     openPlayerModal,
//     updatePlayerLink,
// } from '../../redux/modal/playerModal.action';

export const SmartLink = props => {
    const { href = '', hasArrow, children, as, ...restProps } = props;
    // const dispatch = useDispatch();
    const external = isExternalLink(href);

    if (
        (typeof children === 'string' && children.toLowerCase() === 'download') ||
        (href && href.length > 0 && href.indexOf('.pdf') > -1)
    ) {
        return (
            <Link href={href}>
                <a {...restProps} href={href} target='_blank' rel='noopener noreferrer' download>
                    {children} <span className='icon-download' />
                </a>
            </Link>
        );
    }

    if (href === '' || !href) {
        return (
            <a disabled tabIndex='-1' {...restProps}>
                {children}
                {hasArrow && <span className='icon-arrow-right' />}
            </a>
        );
    }

    // if link container *http* then its an external link
    if (external) {
        const type = checkMediaUrl(href);
        if (type === 'video') {
            return (
                <a
                    disabled
                    tabIndex='-1'
                    onClick={() => {
                        // dispatch(openPlayerModal());
                        // dispatch(updatePlayerLink(href));
                    }}
                >
                    {children}
                    {hasArrow && <span className='icon-arrow-right' />}
                </a>
            );
        } else {
            return (
                <a {...restProps} href={href} target='_blank' rel='noopener noreferrer'>
                    {children}
                    {hasArrow && <span className='icon-arrow-right' />}
                </a>
            );
        }
    }

    if (href !== '') {
        return (
            <Link href={href} as={as}>
                <a {...restProps}>
                    {children}
                    {hasArrow && (
                        <>
                            {' '}
                            <span className='icon-arrow-right' />
                        </>
                    )}
                </a>
            </Link>
        );
    }

    return (
        <a {...restProps} href={href}>
            {children}
            {hasArrow && <span className='icon-arrow-right' />}
        </a>
    );
};

SmartLink.propTypes = {
    href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    as: PropTypes.string,
    hasArrow: PropTypes.bool,
    children: PropTypes.node,
};
