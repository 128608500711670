export function loadScript (scriptSrc) {
    const scriptTag = document.createElement('script');
    scriptTag.src = scriptSrc;
    scriptTag.async = true;
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    return false;
}

export function loadShareThisScript () {
    loadScript(
        'https://platform-api.sharethis.com/js/sharethis.js#property=5ef9856184db27001224151f&product=custom-share-buttons'
    );
}
