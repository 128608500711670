import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';
// import { useWindowWidth } from '@react-hook/window-size';

import { useDispatch, useSelector } from 'react-redux';
import { closePlayerModal } from '../../redux/modal/playerModal.action';
import { playerModalGetLink, playerModalGetPoster } from '../../redux/modal/playerModal.selector';
import Player from '../Player';

//* style
import style from './Player.module.scss';

const PlayerPopup = () => {
    const dispatch = useDispatch();
    const state = useSelector(s => s);
    const link = playerModalGetLink(state);
    const poster = playerModalGetPoster(state);

    // const isMobile = useMediaQuery('only screen and (max-width: 650px)');
    // const winWidth = useWindowWidth();

    return (
        <div className={style.el}>
            <div className={[style.container, style.modal].join(' ')}>
                <div className={style.videoFrame}>
                    {link && (
                        <div className={style.vimeoPlayer}>
                            <div
                                className={style.close}
                                onClick={() => {
                                    dispatch(closePlayerModal());
                                }}
                            >
                                <span />
                                <span />
                            </div>
                            <Player link={link} poster={poster} isAutoPlay />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

PlayerPopup.propTypes = {
    link: PropTypes.string,
};

PlayerPopup.defaultProps = {};

export default PlayerPopup;
