import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useDispatch } from 'react-redux';

import { setGTMconsent } from '../../utils/gtm';
import { setCookieConsent } from '../../redux/global/global.action';
import style from './CookiesBanner.module.scss';

const CookiesBanner = props => {
    const { description, acceptBtn, rejectBtn } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (getCookieConsentValue() === 'true') {
            setGTMconsent(true);
        }
    }, []);

    return (
        <CookieConsent
            location='bottom'
            buttonText={acceptBtn}
            enableDeclineButton
            declineButtonText={rejectBtn}
            disableStyles={true}
            containerClasses={style.container}
            contentClasses={style.contentWrap}
            overlayClasses={style.overlay}
            buttonWrapperClasses={style.buttonsWrap}
            declineButtonClasses={style.declineBtn}
            onAccept={() => {
                setGTMconsent(true);
                dispatch(setCookieConsent(true));
            }}
            onDecline={() => {
                setGTMconsent(false);
                dispatch(setCookieConsent(false));
            }}
        >
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </CookieConsent>
    );
};

CookiesBanner.propTypes = {
    description: PropTypes.string,
    acceptBtn: PropTypes.string,
    rejectBtn: PropTypes.string,
};
export default CookiesBanner;

CookiesBanner.defaultProps = {};
